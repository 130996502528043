

const Web = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title" style={{color: "green", "font-weight": "bold"}}>Development</h3>
      <div className="skills__box">

           <div className="skills__group">
              <div className="skills__data">
                  <i style={{color: "green"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">React</h3>
                    
                  </div>
              </div>

              <div className="skills__data">
                  <i style={{color: "green"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">Node Js</h3>
                    
                  </div>
              </div>

              <div className="skills__data">
                  <i style={{color: "green"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">Express</h3>
                    
                  </div>
              </div>
           </div>

           <div className="skills__group">
              <div className="skills__data">
                  <i style={{color: "green"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">JavaScript</h3>
                    
                  </div>
              </div>

              <div className="skills__data">
                  <i style={{color: "green"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">MySQL</h3>
                    
                  </div>
              </div>


              <div className="skills__data">
                  <i style={{color: "green"}} className="bx bx-badge-check"></i>
                  <div>
                    <h3 className="skills__name">NoSQL</h3>
                    
                  </div>
              </div>
           </div>
      </div>
    </div>
  )
}

export default Web;