import { useState } from "react";
import "./qualification.css";

const Qualification = () => {

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <section className="qualification section" id="qualification">

    <h3 className="section__title"> Qualification </h3>
    <span className="section__subtitle"> My Technical Level </span>

    <div className="qualification__container container grid">
            <div className="qualification__tabs">
              <div style={{color : "black"}} onClick={() => toggleTab(1)} className={ toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} >
                <i  className="uil uil-graduation-cap qualification__icon"></i> {" "} Education 
              </div>
              <div style={{color : "black"}} onClick={() => toggleTab(2)}  className={ toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} >
                <i  className="uil uil-briefcase-alt qualification__icon"></i> {" "} Experience
              </div>
            </div>
            <div className="qualification__sections">
                    <div  className={ toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"} >

                      <div className="qualification__data">
                        <div>
                          <h3 className="qualification__title"> Computer Engineering(ECE) </h3>
                          <span className="qualification__subtitle"> Aksum University </span>
                          <div className="qualification__calender">
                            <i style={{color: "grey"}} className="uil uil-calender">2018 - 2022</i>
                          </div>
                        </div>
                        <div>
                          <span className="qualification__rounder"></span>
                          <span className="qualification__line"></span>
                        </div>
                      </div>

                      <div className="qualification__data">
                                <div></div>
                                  <div>
                                  <span className="qualification__rounder"></span>
                                  <span className="qualification__line"></span>
                                </div>
                        <div>
                          <h3 className="qualification__title">Computer Maintenance</h3>
                          <span className="qualification__subtitle"> Aksum University </span>
                          <div className="qualification__calender">
                            <i style={{color: "grey"}} className="uil uil-calender">2020</i>
                          </div>
                        </div>
                        
                      </div>

                      <div className="qualification__data">
                        <div>
                          <h3 className="qualification__title"> Web Developer (intern) </h3>
                          <span className="qualification__subtitle"> ZalaTech PLC</span>
                          <div className="qualification__calender">
                            <i style={{color: "grey"}} className="uil uil-calender">2021</i>
                          </div>
                        </div>
                        <div>
                          <span className="qualification__rounder"></span>
                          <span className="qualification__line"></span>
                        </div>
                      </div>


                      <div className="qualification__data">
                                <div></div>
                                  <div>
                                  <span className="qualification__rounder"></span>
                                  <span className="qualification__line"></span>
                                </div>
                        <div>
                          <h3 className="qualification__title">Cloud Computing</h3>
                          <span className="qualification__subtitle"> ALX Africa </span>
                          <div className="qualification__calender">
                            <i style={{color: "grey"}} className="uil uil-calender">2023</i>
                          </div>
                        </div>
                        
                      </div>
                      


                    </div>

                    <div  className={ toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"} >

                          <div className="qualification__data">
                            <div>
                              <h3 className="qualification__title"> Web Developer Intern</h3>
                              <span className="qualification__subtitle"> ZalaTech PLC - Addis Ababa</span>
                              <div className="qualification__calender">
                                <i style={{color: "grey"}} className="uil uil-calender"> Jan - Apr. 2020</i>
                              </div>
                            </div>
                            <div>
                              <span className="qualification__rounder"></span>
                              <span className="qualification__line"></span>
                            </div>
                          </div>

                          <div className="qualification__data">
                                    <div></div>
                                      <div>
                                      <span className="qualification__rounder"></span>
                                      <span className="qualification__line"></span>
                                    </div>
                            <div>
                              <h3 className="qualification__title">Golang Developer Intern</h3>
                              <span className="qualification__subtitle"> 2F Capital - Addis Ababa</span>
                              <div className="qualification__calender">
                                <i style={{color: "grey"}} className="uil uil-calender"> June - Aug. 2022</i>
                              </div>
                            </div>                            
                          </div>

                          <div className="qualification__data">
                            <div>
                              <h3 className="qualification__title"> E-Commerce Website Developer </h3>
                              <span className="qualification__subtitle"> Xclusive Garment - Addis Ababa</span>
                              <div className="qualification__calender">
                                <i style={{color: "grey"}} className="uil uil-calender"> Aug - Jan. 2023</i>
                              </div>
                            </div>
                            <div>
                              <span className="qualification__rounder"></span>
                              <span className="qualification__line"></span>
                            </div>
                          </div>  
  

                          <div className="qualification__data">
                                    <div></div>
                                      <div>
                                      <span className="qualification__rounder"></span>
                                      <span className="qualification__line"></span>
                                    </div>
                            <div>
                              <h3 className="qualification__title">Full Stack Developer </h3>
                              <span className="qualification__subtitle"> Vintage Technologies PLC - Addis Ababa</span>
                              <div className="qualification__calender">
                                <i style={{color: "grey"}} className="uil uil-calender"> Mar.- Jun. 2023</i>
                              </div>
                            </div>                            
                          </div>             

                    </div>
                    

            </div>
    </div>

</section>
  )
}

export default Qualification