

const DevOps = () => {
  return ( 
    <div className="skills__content">
    <h3 className="skills__title" style={{color: "blue", "font-weight": "bold"}}>DevOps</h3>
    <div className="skills__box">

         <div className="skills__group">
            <div className="skills__data">
                <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">Ansible</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">IaC</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">Containerization</h3>
                  
                </div>
            </div>
         </div>

         <div className="skills__group">
            <div className="skills__data">
                <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">CI/CD</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">Monitoring</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "blue"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">Logging</h3>
                  
                </div>
            </div>
         </div>
    </div>
  </div>
  )
}

export default DevOps;