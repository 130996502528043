import "./service.css";
import { useState } from "react"

const Services = () => {
  const [toggleState, setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <section className="services section" id="services">
            <h3 className="section__title"> Services </h3>
            <span className="section__subtitle"> What Can I Offer ?  </span>

            <div className="services__container container grid">

                <div className="services__content">
                    <div>
                        <i className="uil uil-cloud services__icon"></i>
                        <h3 className="services__title">Cloud <br /> Architecting</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(1)}>View More <i className="uil uil-arrow-right  services__button-icon"></i></span>

                    <div className={toggleState === 1 ? "services__modal active_modal" : "services__modal"}>
                      <div className="services__modal-content">
                          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                          <h3 className="services__modal-title" style={{color: "blue"}}>Cloud Computing</h3>
                          <p className="services__modal-description">
                            Service with a better experience. Providing you quality 
                              work to clients and your companies.</p>
                                <ul className="services__modal-services grid">
                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Provision and manage virtual servers in the cloud, enabling scalablity and flexility.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Store and retrieve any amount of data securely and durably.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Set up, operate, and scale relational databases in the cloud, offering automated backups.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Create isolated virtual networks in the cloud, your own network topology.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Manage user identities and permissions, controlling access to AWS resources and services.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Monitor and troubleshoot AWS resources and applications in real-time.</p>
                                  </li>

                                </ul>
                      </div>
                    </div>
                </div>


                <div className="services__content">
                    <div>
                    <i className="uil uil-arrow services__icon"></i>
                    <h3 className="services__title">DevOps <br/> Services</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(2)}>View More <i className="uil uil-arrow-right  services__button-icon"></i></span>

                    <div className={toggleState === 2 ? "services__modal active_modal" : "services__modal"}>
                      <div className="services__modal-content">
                          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                          <h3 className="services__modal-title" style={{color: "blue"}}>Deployment and Monitoring</h3>
                          <p className="services__modal-description">
                            Service with a better experience. Providing quality 
                              work to clients and your companies.</p>
                                <ul className="services__modal-services grid">
                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Automate the build, test, and deployment processes, enabling CI/CD pipelines.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Utilize fully managed build service to compile source code, tests, and produce artifacts.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Automate software deployments to AWS EC2, AWS Lambda, and Fargate.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Host secure and scalable Git repositories in the cloud, facilitating collaborative workflows.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Monitor and troubleshoot applications and infrastructure resources in real-time.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Define infrastructure as code and provision AWS resources in a predictable and repeatable manner.</p>
                                  </li>

                                </ul>
                      </div>
                    </div>
                </div>


                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">Website <br /> Designer</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(3)}>View More <i className="uil uil-arrow-right  services__button-icon"></i></span>

                    <div className={toggleState === 3 ? "services__modal active_modal" : "services__modal"}>
                      <div className="services__modal-content">
                          <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                          <h3 className="services__modal-title" style={{color: "blue"}}>Website Development</h3>
                          <p className="services__modal-description">
                            Service with a better experience. Providing you quality 
                              work to clients and your companies.</p>
                                <ul className="services__modal-services grid">
                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Building fully customized websites using a combination of front-end and back-end.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Creating websites that adapt and look great on all devices.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Crafting user interfaces and experiences using frameworks like React or Vue.js.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Developing server side applications, APIs, and databases with frameworks like Express or Laravel.</p>
                                  </li>

                                  <li className="services__modal-service">
                                    <i  style={{color: "green"}} className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">Designing, implementing, and managing databases using technologies like SQL or NoSQL .</p>
                                  </li>

                                </ul>
                      </div>
                    </div>
                </div>

            </div>
    </section>
  )
}

export default Services