

const AWS_Cloud = () => {
  return ( 
    <div className="skills__content">
    <h3 className="skills__title" style={{color: "green", "font-weight": "bold"}}>AWS Cloud</h3>
    <div className="skills__box">

         <div className="skills__group">
            <div className="skills__data">
                <i style={{color: "green"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">CloudFormation</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "green"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">AWS IAM</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "green"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">AWS RDS</h3>
                  
                </div>
            </div>
         </div>

         <div className="skills__group">
            <div className="skills__data">
                <i style={{color: "green"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">AWS EC2</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "green"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">AWS S3</h3>
                  
                </div>
            </div>

            <div className="skills__data">
                <i style={{color: "green"}} className="bx bx-badge-check"></i>
                <div>
                  <h3 className="skills__name">AWS Lambda</h3>
                  
                </div>
            </div>
         </div>
    </div>
  </div>
  )
}

export default AWS_Cloud;