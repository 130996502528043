import Image1 from "./../../assets/client-01.jpg";
import Image2 from "./../../assets/client-02.jpg";
import Image3 from "./../../assets/client-03.jpg";

export const Data = [
  {
    id: 1,
    image: Image2,
    title: "Matusala Abreha",
    description:
      "'When I first started working with you, I wasn't aware I would be collaborating with such a skilled co-worker.' - Upwork Freelancer",
  },
  {
      id: 2,
      image: Image1,
      title: "Habtamu Mangesha",
      description:
        "'A really good job, all aspects of Xclusive Garment were followed step by step and with good results.' - Xclusive Garment",
    },
    {
      id: 3,
      image: Image3,
      title: "Ameha Wendemu",
      description:
        "'Meku, It was nice working with you. I hope we will meet on other project as well. you did a great job' - ZalaTech PLC",
    },
  ];